































.cc-end-time
  position absolute
  top: -17px;
  color: #A0A0A0;
  font-size: 12px;
  right: -14px;
