.form {
  padding: 0 16px;
}
.stepper {
  border-radius: 0;
  border-bottom: 1px solid #808080;
}
small {
  height: 90%;
  display: block;
  height: 36px;
}
.search-header {
  padding-left: 0;
}
/*# sourceMappingURL=src/pages/ondemand/carclub/sidebar.css.map */