.selection-bar {
  position: absolute;
  display: grid;
  height: 48px;
  top: -2px;
  user-select: none;
  box-sizing: content-box;
  cursor: grab;
  grid-template-columns: 15px auto 15px;
}
.selection-bar > small {
  margin: auto;
  text-align: center;
  color: #fff;
}
.start,
.end {
  height: 100%;
  cursor: col-resize;
}
.extend-handle {
  transform: rotate(90deg);
  font-size: 36px;
  position: absolute;
  width: 10px;
  top: -5px;
}
.start {
  left: 0;
}
.start .extend-handle {
  left: 3px;
}
.end {
  right: 0;
}
.end .extend-handle {
  right: 3px;
}
.unavailable {
  background: rgba(255,160,0,0.9);
  border: 2px solid rgba(255,160,0,0.9);
}
.available {
  background: rgba(56,142,60,0.9);
  border: 2px solid rgba(56,142,60,0.9);
}
.start-date {
  position: absolute;
  top: -28px;
  left: -18px;
  font-size: 16px;
  color: #000;
  background: #fff;
}
.end-date {
  position: absolute;
  top: -28px;
  right: -20px;
  font-size: 16px;
  color: #000;
  background: #fff;
}
/*# sourceMappingURL=src/pages/ondemand/carclub/magic-bar/selection-bar.css.map */