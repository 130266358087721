




















































































.visual
  position relative
  margin: 20px 10px;
  height 50px
  border 1px solid black

