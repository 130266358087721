












































































































































































































.layout-padding
  @media (max-width: 1199px)
    padding 1em

.window *
  user-select: none

header
  margin-bottom 16px
h1
  font-size 24px
  font-weight 400
  line-height 2
.vehicle-info
  padding: 16px
.center
  display grid

  > *
    margin auto

.window-limit
  font-size: 13px
  text-align center

.dates-container
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 600px;
  margin: auto;
  padding-bottom: 10px;

.arrow
  display grid
  margin auto

.time
  font-size: 24px
