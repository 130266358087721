















































































.details
  width 100%

.vehicle
  display flex
  transition transform .2s ease-in
  cursor pointer
  user-select none
  padding 8px!important

.car-image
  display flex
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding 8px

.car-image img
  display block

.bg-selected
  background #d7eef9

.vehicle-title
  text-transform capitalize
  width 100%
  display flex
  justify-content space-between

