

































.cc-segment
  height 48px
  display inline-block
  position relative

.cc-segment:first-child
  border-left 0!important

.cc-block-time
  position absolute
  top: -17px;
  color: #A0A0A0;
  font-size: 12px;
  left: -14px;
