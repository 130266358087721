




















































































































































.mobile
  .code
    display none
  .column.container
    margin 32px
  .cta
    background-color #fafafa
    padding 16px 8px
    position fixed
    left 0
    right 0
    bottom 0
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 5px 8px rgba(0,0,0,0.14), 0 1px 14px rgba(0,0,0,0.12);
.cta
  padding 8px
.code
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .7)
  overflow hidden
  background-image url('~assets/backgrounds/cc.jpg')
  background-position: 0 34%;
  background-size: cover;
  text-overflow: clip;

.column.container
  margin-top -72px

.q-card
  width 80vw
  max-width 600px

.notice
  display grid
  grid-auto-rows auto
  grid-row-gap 20px
  text-align center

  *
    margin auto
