@media (max-width: 1199px) {
  .layout-padding {
    padding: 1em;
  }
}
.search-results {
  max-width: 768px;
  margin: auto;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader {
  width: 100px;
  height: 100px;
  margin: auto;
}
h1 {
  font-size: 25px;
  line-height: 2;
}
/*# sourceMappingURL=src/pages/ondemand/carclub/results.css.map */